import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import { LanguageSwitcher } from '../i18n/components/LanguageSwitcher';
import { Logo } from './Logo';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Sizes } from '../constants/sizes';
import { AnchoredLink } from './AnchoredLink';
import { Device } from '../constants/device';
import menuIcon from '../../assets/images/menu.svg';
import closeIcon from '../../assets/images/close.svg';
import { Banner } from './Banner';
import { StyledMenuLink } from './StyledMenuLink';

export const Header = (): ReactElement | null => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const {
    state: { project },
  } = useAppContext();
  const primaryLinks = ['features', 'references', 'faq'];
  const secondaryLinks = ['teams', 'contact'];

  return (
    <FixedHeader>
      <Banner />
      <Container>
        <HeaderContent>
          <Logo project={project} />
          <StyledNavigation>
            <PrimaryNavigation>
              {primaryLinks.map((section, key) => (
                <AnchoredLink key={key} anchor={section}>
                  <FormattedMessage id={'section.' + section} />
                </AnchoredLink>
              ))}
            </PrimaryNavigation>
            <SecondaryNavigation>
              {secondaryLinks.map((section, key) => (
                <AnchoredLink key={key} anchor={section}>
                  <FormattedMessage id={'section.' + section} />
                </AnchoredLink>
              ))}
              <StyledMenuLink href={'https://mildblue.slp.blue/verejna-prirucka/stranka/domovska-stranka'} target={'_blank'} rel="noopener noreferrer">
                {project?.resources.handbook_button}
              </StyledMenuLink>
              <HeaderDivider />
              <LanguageSwitcher />
            </SecondaryNavigation>
          </StyledNavigation>

          <Burger onClick={() => setShowMobileMenu((show) => !show)}>
            <img src={menuIcon} alt="Menu icon" />
          </Burger>

          {showMobileMenu && (
            <MobileMenu>
              <MobileHeaderContent>
                <Logo project={project} />
                <Burger onClick={() => setShowMobileMenu((show) => !show)}>
                  <img src={closeIcon} alt="Close icon" />
                </Burger>
              </MobileHeaderContent>
              <NavContainer>
                <nav>
                  {[...primaryLinks, ...secondaryLinks].map((section, key) => (
                    <AnchoredLink key={key} anchor={section} onClick={() => setShowMobileMenu(false)}>
                      <FormattedMessage id={'section.' + section} />
                    </AnchoredLink>
                  ))}
                </nav>
                <LanguageSwitcher />
              </NavContainer>
            </MobileMenu>
          )}
        </HeaderContent>
      </Container>
    </FixedHeader>
  );
};

const HeaderDivider = styled.div`
  width: 2px;
  height: 25px;
  background: ${Colors.primary};
  margin-right: 20px;
  padding-top: 15px;
  display: none;

  ${Device.desktop} {
    display: block;
  }
`;

const Burger = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Device.mobile} {
    height: 20px;
    width: 20px;
  }

  img {
    width: 100%;
  }
`;

const FixedHeader = styled.header`
  width: 100%;
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px 0;
  height: ${Sizes.HeaderHeight}px;

  ${Burger} {
    display: none;

    ${Device.laptop} {
      display: block;
    }
  }
`;

const MobileHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${Sizes.HeaderHeight}px;
  padding: 0 30px;
  width: 100%;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-bottom: ${Sizes.HeaderHeight}px;
`;

const StyledNavigation = styled.nav`
  flex: 1;
  display: flex;
  align-items: start;
  margin-top: 18px;

  ${Device.laptop} {
    display: none;
  }

  a {
    padding: 0 40px;

    ${Device.desktop} {
      color: ${Colors.primary};
      padding: 0 25px;
    }
  }
`;

const PrimaryNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 30px;

  ${Device.desktop} {
    padding-right: 0;
  }

  a {
    color: ${Colors.primary};
  }
`;

const SecondaryNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-basis: ${Sizes.JumbotronRightWidth}px;
  padding-right: 30px;

  ${Device.fullHdDown} {
    flex-basis: 590px;
  }

  ${Device.desktop} {
    flex-basis: auto;
    padding-right: 0;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  z-index: 100;
  background: ${Colors.white};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }

  a {
    color: ${Colors.primary};
    margin: 10px 0;
  }
`;
